@font-face {
  font-family: 'Montreal';
  src: url('/assets/fonts/montreal/MontrealTS-Bold.eot');
  src: url('/assets/fonts/montreal/MontrealTS-Bold.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/montreal/MontrealTS-Bold.woff2') format('woff2'), url('/assets/fonts/montreal/MontrealTS-Bold.woff') format('woff'), url('/assets/fonts/montreal/MontrealTS-Bold.svg#MontrealTS-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-F64D;
}
.montreal-bold {
  font-family: 'UniversLTCYR', Helvetica, Arial, sans-serif;
  font-weight: bold;
}
@font-face {
  font-family: 'Montreal';
  src: url('/assets/fonts/montreal/MontrealTS-DemiBold.eot');
  src: url('/assets/fonts/montreal/MontrealTS-DemiBold.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/montreal/MontrealTS-DemiBold.woff2') format('woff2'), url('/assets/fonts/montreal/MontrealTS-DemiBold.woff') format('woff'), url('/assets/fonts/montreal/MontrealTS-DemiBold.svg#MontrealTS-DemiBold') format('svg');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-F64D;
}
.montreal-demibold {
  font-family: 'UniversLTCYR', Helvetica, Arial, sans-serif;
  font-weight: 500;
}
@font-face {
  font-family: 'Montreal';
  src: url('/assets/fonts/montreal/MontrealTS-Regular.eot');
  src: url('/assets/fonts/montreal/MontrealTS-Regular.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/montreal/MontrealTS-Regular.woff2') format('woff2'), url('/assets/fonts/montreal/MontrealTS-Regular.woff') format('woff'), url('/assets/fonts/montreal/MontrealTS-Regular.svg#MontrealTS-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-F64D;
}
.montreal-regular {
  font-family: 'UniversLTCYR', Helvetica, Arial, sans-serif;
  font-weight: normal;
}
@font-face {
  font-family: 'Montreal';
  src: url('/assets/fonts/montreal/MontrealTS-Light.eot');
  src: url('/assets/fonts/montreal/MontrealTS-Light.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/montreal/MontrealTS-Light.woff2') format('woff2'), url('/assets/fonts/montreal/MontrealTS-Light.woff') format('woff'), url('/assets/fonts/montreal/MontrealTS-Light.svg#MontrealTS-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-F64D;
}
.montreal-light {
  font-family: 'UniversLTCYR', Helvetica, Arial, sans-serif;
  font-weight: 300;
}
@font-face {
  font-family: 'UniversLTCYR';
  src: url('/assets/fonts/univers/UniversLTCYR-65Bold.eot');
  src: url('/assets/fonts/univers/UniversLTCYR-65Bold.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/univers/UniversLTCYR-65Bold.woff2') format('woff2'), url('/assets/fonts/univers/UniversLTCYR-65Bold.woff') format('woff'), url('/assets/fonts/univers/UniversLTCYR-65Bold.svg#UniversLTCYR-65Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0-10FFFF;
}
@font-face {
  font-family: 'UniversLTCYR';
  src: url('/assets/fonts/univers/UniversLTCYR-45Light.eot');
  src: url('/assets/fonts/univers/UniversLTCYR-45Light.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/univers/UniversLTCYR-45Light.woff2') format('woff2'), url('/assets/fonts/univers/UniversLTCYR-45Light.woff') format('woff'), url('/assets/fonts/univers/UniversLTCYR-45Light.svg#UniversLTCYR-45Light') format('svg');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0-10FFFF;
}
@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/icons/icomoon.eot?7zbuf4');
  src: url('/assets/fonts/icons/icomoon.eot?7zbuf4#iefix') format('embedded-opentype'), url('/assets/fonts/icons/icomoon.ttf?7zbuf4') format('truetype'), url('/assets/fonts/icons/icomoon.woff?7zbuf4') format('woff'), url('/assets/fonts/icons/icomoon.svg?7zbuf4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-arrow-l:before {
  content: "\e900";
}
.icon-arrow-r:before {
  content: "\e901";
}
.icon-close:before {
  content: "\e902";
}
.icon-doc:before {
  content: "\e903";
}
.icon-earth:before {
  content: "\e904";
}
.icon-edit:before {
  content: "\e905";
}
.icon-email:before {
  content: "\e906";
}
.icon-empower:before {
  content: "\e907";
}
.icon-eu:before {
  content: "\e908";
}
.icon-info:before {
  content: "\e909";
}
.icon-monitor:before {
  content: "\e90a";
}
.icon-ok:before {
  content: "\e90b";
}
.icon-phone:before {
  content: "\e90c";
}
.icon-plus:before {
  content: "\e90d";
}
.icon-scroll-down:before {
  content: "\e90e";
}
.icon-strategy:before {
  content: "\e90f";
}
.icon-sync:before {
  content: "\e910";
}
.icon-vision:before {
  content: "\e911";
}
.spinner {
  display: none;
  width: 70px;
  margin-top: 0;
  text-align: center;
}
.spinner div {
  width: 10px;
  height: 10px;
  margin: 0;
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/*

    !!!!! We are using desktop first approach !!!!!

*/
/* Rainbow test, to enable add asterisk here->/
body:before{
    border-width: 1em;
    border-style: solid;
    padding: 2em;
    content: "ಠ▃ಠ";
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    border-color: #FF7F00;
    color: #4B0082;

    .on(@4k, {
        border-color: #FF0000;
        color: #9400D3;
    });

    .on(@laptop, {
        color: #0000FF;
        border-color: #FFFF00;
    });

    .on(@mobile, {
        color: #00FF00;
        border-color:  	#00FF00;
    })
}
/* - */
html {
  font-family: 'Montreal', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  color: #323232;
  letter-spacing: .04em;
  line-height: 1;
}
html,
body,
body > main {
  min-height: 100vh;
}
mark {
  background: none;
}
template {
  display: none !important;
}
h1,
.h1 {
  font-size: 38px;
}
h2,
.h2 {
  font-size: 20px;
}
h1,
h2,
h3,
.h1,
.h2,
.h3 {
  color: #009CDA;
  font-weight: 500;
  font-family: 'Montreal', Helvetica, Arial, sans-serif;
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  h1,
  h2,
  h3,
  .h1,
  .h2,
  .h3 {
    margin-top: 2em;
    font-size: 150%;
  }
}
p {
  font-family: 'UniversLTCYR', Helvetica, Arial, sans-serif;
  line-height: 1.5;
  margin-bottom: 0;
}
p strong {
  font-weight: bold;
}
a,
button.link {
  text-decoration: none;
  background-color: transparent;
  color: #009CDA;
  outline: none;
  padding: 0;
  margin: 0;
  display: inline;
  border: none;
  box-shadow: none;
}
a:active,
button.link:active {
  color: #009CDA;
}
a:hover,
button.link:hover {
  color: #a6dbf2;
}
.indicator {
  border-width: 1px;
  border-radius: 50%;
  border-style: solid;
  border-color: #009CDA;
  height: 2.5em;
  width: 2.5em;
  padding: 0;
  font-style: normal;
  text-align: center;
  vertical-align: baseline;
  text-transform: uppercase;
  line-height: 2.5em;
  font-size: 25px;
}
button,
.button {
  cursor: pointer;
  outline: none;
  padding: 0.5em 2em;
  background-color: transparent;
  border-radius: 0.5em;
  display: inline-block;
  margin-right: 0.5em;
  margin-left: 0.5em;
  border: 1px solid #009CDA;
  color: #009CDA;
  opacity: 1;
  transition: background-color, opacity 0.2s ease, color 0.2s ease;
}
button:disabled,
.button:disabled {
  filter: grayscale(100%);
  pointer-events: none;
  cursor: pointer;
}
button:hover:not(:disabled),
.button:hover:not(:disabled) {
  color: white;
  background-color: #009CDA;
}
button.disabled span,
.button.disabled span {
  display: none;
}
button.disabled .spinner,
.button.disabled .spinner {
  display: block;
}
button.neutral,
.button.neutral {
  color: #323232;
  border-color: #323232;
  border-radius: 1em;
  font-size: 80%;
}
button.neutral:hover,
.button.neutral:hover {
  color: #323232;
  background-color: white;
}
button.inverted,
.button.inverted {
  color: #EBEBEB;
  border-color: #EBEBEB;
}
button.inverted.active,
.button.inverted.active {
  background-color: white;
  color: #009CDA;
}
button.danger,
.button.danger {
  color: #e95e28;
  border-color: #e95e28;
}
button.danger:hover,
.button.danger:hover {
  color: white;
  background-color: #e95e28;
}
button.success,
.button.success {
  color: #97be0d;
  border-color: #97be0d;
}
button.success:hover,
.button.success:hover {
  color: white;
  background-color: #97be0d;
}
button.hot,
.button.hot {
  color: #dc006b;
  border-color: #dc006b;
}
button.hot:hover,
.button.hot:hover {
  color: white;
  background-color: #dc006b;
}
button.warning,
.button.warning {
  color: #f39900;
  border-color: #f39900;
}
button.warning:hover,
.button.warning:hover {
  color: white;
  background-color: #f39900;
}
body.resources .page-header button:hover,
body.hub .page-header button:hover,
body.resources .page-header .button:hover,
body.hub .page-header .button:hover {
  background: #fff;
  color: #009CDA;
}
button[data-action="select-all"].inverted,
button[data-action="select-all-countries"].inverted,
button[data-action="select-all"]:hover,
button[data-action="select-all-countries"]:hover {
  background-color: #323232;
  color: white;
  border-color: white;
}
nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  left: 0;
  right: 0;
  padding-left: 1em;
  padding-right: 1em;
}
nav a {
  display: block;
  padding: 0.5em 2em;
  align-self: center;
  font-size: 16px;
}
nav.main {
  position: fixed;
  top: 0;
  z-index: 50;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.85);
}
nav.main:not(.open) .active {
  font-weight: bold;
}
nav.main .privacy {
  display: none;
}
nav.main > a:first-child {
  margin-top: auto;
  margin-bottom: auto;
}
@media only screen and (max-width: 1024px) {
  nav.main > a:first-child {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
nav.main > a:first-child > img {
  width: 10em;
}
nav.main > a:first-child > img.alt {
  display: none;
}
nav.main > div:not(.nag) {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-content: flex-end;
  margin-top: auto;
  margin-bottom: auto;
}
nav.main > .menu {
  margin-left: auto;
}
nav.main > .menu a {
  color: #323232;
}
nav.main > .menu a:visited {
  color: inherit;
}
nav.main > .menu a:hover {
  color: #009CDA;
}
nav.main > .menu a.active {
  color: #009CDA;
}
@media only screen and (max-width: 1024px) {
  nav.main {
    align-items: flex-start;
  }
  nav.main > div:not(.nag) {
    flex-grow: 2;
    text-align: center;
    flex-flow: column nowrap;
  }
  nav.main > div:not(.nag) a {
    color: #323232;
    display: none;
    margin: .8em 2em;
  }
}
nav.main .hamburger {
  right: 0;
  top: 0;
  align-self: flex-start;
  margin-bottom: 0;
  display: none;
  padding: 2em;
  height: 40px;
  box-shadow: none;
  outline: none;
  text-align: right;
  line-height: 1;
  box-sizing: content-box;
  position: absolute;
}
nav.main .hamburger:hover,
nav.main .hamburger:active {
  background-color: transparent;
  opacity: 1;
}
nav.main .hamburger > span > span {
  background-color: #323232;
}
nav.main .hamburger > span > span:before,
nav.main .hamburger > span > span:after {
  background-color: #323232;
}
@media only screen and (max-width: 1024px) {
  nav.main .hamburger {
    display: block;
  }
}
@media only screen and (max-width: 1024px) {
  nav.main.open {
    background-color: white;
    z-index: 51;
  }
  nav.main.open > a:first-child {
    position: absolute;
    top: 0;
  }
  nav.main.open > div:not(.nag) {
    height: 100vh;
  }
  nav.main.open > div:not(.nag) > a {
    display: block;
  }
}
nav.secondary {
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  margin-top: 0;
  width: 100%;
  z-index: 50;
  background-color: rgba(0, 156, 218, 0.8);
}
nav.secondary > a {
  margin: 0;
  color: #EBEBEB;
  padding-top: 1em;
  padding-bottom: 1em;
  text-decoration: none;
}
nav.secondary > a:visited {
  color: #EBEBEB;
}
nav.secondary > a:first-child {
  margin-left: auto;
}
nav.secondary > a:last-child {
  margin-right: auto;
}
nav.secondary > a.active {
  color: #EBEBEB;
  font-weight: 500;
  text-decoration: none;
}
nav.secondary > a:hover {
  color: #EBEBEB;
  text-decoration: none;
}
nav.cards {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0;
  padding-left: 0;
  max-width: 710px;
  box-sizing: border-box;
}
@media only screen and (max-width: 1024px) {
  nav.cards {
    justify-content: space-around;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  nav.cards {
    display: block;
    min-width: 100%;
    margin-top: 0;
  }
}
nav.cards > a {
  box-sizing: border-box;
  min-height: 340px;
  flex: 1 0 300px;
  max-width: 300px;
  color: white;
  padding: 1em;
  text-align: left;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.4);
  align-self: flex-start;
  margin-bottom: 2em;
  line-height: 1.2;
  margin-top: 0;
  margin-left: 1em;
  margin-right: 1em;
}
@media only screen and (max-width: 767px) {
  nav.cards > a {
    flex: 1 0 100%;
    margin-left: 2em;
    margin-right: 2em;
    width: auto;
    max-width: 100%;
    margin-bottom: 0;
    margin-top: 2em;
  }
}
nav.cards > a i {
  border: 2px solid white;
  display: block;
  float: left;
  margin-right: 1em;
  text-align: center;
}
nav.cards > :nth-child(even) {
  margin-bottom: auto;
  position: relative;
  top: 4em;
}
@media only screen and (max-width: 767px) {
  nav.cards > :nth-child(even) {
    position: static;
  }
}
nav.cards > :last-child {
  margin-right: calc(320px + 2em);
  margin-left: 1em;
}
@media only screen and (max-width: 767px) {
  nav.cards > :last-child {
    margin-right: 2em;
    margin-left: 2em;
  }
}
nav.dots {
  position: fixed;
  flex-direction: column;
  left: 2em;
  width: auto;
  max-width: none;
  margin: 0 0;
  right: auto;
  top: 50%;
}
@media only screen and (max-width: 1024px) {
  nav.dots {
    display: none;
  }
}
nav.dots > a {
  border: 1px solid #009CDA;
  border-radius: 50%;
  background-color: white;
  font-size: 1em;
  padding: 0;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-transform: uppercase;
  margin-bottom: 1em;
  margin-top: 1em;
}
nav.dots > a:hover {
  background-color: #009CDA;
  color: white;
  border-color: #a6dbf2;
}
nav.dots > a.active {
  background-color: #009CDA;
  color: white;
  border-color: white;
  width: 3em;
  height: 3em;
  line-height: 3em;
}
nav.buttons {
  display: block;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  nav.buttons > .button {
    margin-top: 2em;
  }
}
.nag {
  display: none;
  flex-direction: row;
  align-content: center;
  left: 0;
  right: 0;
  margin: 0;
  text-align: center;
  padding: 0.5em;
  z-index: 10;
}
@media only screen and (max-width: 767px) {
  .nag {
    padding: 1em;
  }
  .nag p {
    text-align: left;
  }
}
.nag.active {
  display: flex;
}
.nag > * {
  margin-top: auto;
  margin-bottom: auto;
}
.nag > :first-child {
  margin-left: auto;
}
.nag.report {
  position: absolute;
  top: 100%;
  background-color: white;
  color: #009CDA;
}
.nag.report a.button {
  padding: 0.3em;
  margin-right: auto;
}
.nag.cookies {
  position: fixed;
  background-color: #009CDA;
  color: white;
  bottom: 0;
}
.nag.cookies .close {
  color: white;
  padding: 0.4em;
  pointer-events: auto;
  cursor: pointer;
  align-self: center;
  margin: auto 1em auto auto;
  line-height: 1;
}
@media only screen and (max-width: 767px) {
  .nag.cookies .close {
    margin-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .nag.cookies {
    flex-wrap: wrap;
  }
  .nag.cookies p {
    flex-grow: 2;
    order: 1;
    flex-shrink: 3;
    width: 50%;
  }
  .nag.cookies .close {
    order: 2;
    margin: 0;
    flex-shrink: 2;
    margin-left: auto;
  }
  .nag.cookies a {
    order: 3;
    margin-right: auto;
    margin-top: 1em;
    margin-left: 0 ;
  }
}
body > main {
  text-align: center;
  margin-bottom: 5em;
}
body > main * + * {
  margin-top: 3em;
}
@media only screen and (max-width: 1024px) {
  body > main * + * {
    margin-top: 2em;
  }
}
body > main br,
body > main hr {
  margin-top: 0;
}
body > main > section,
body > main > form > section,
body > main > nav,
body > main > form > nav {
  margin-left: auto;
  margin-right: auto;
  width: 60vw;
}
@media only screen and (min-width: 1921px) {
  body > main > section,
  body > main > form > section,
  body > main > nav,
  body > main > form > nav {
    width: 80vw;
  }
}
@media only screen and (max-width: 1024px) {
  body > main > section,
  body > main > form > section,
  body > main > nav,
  body > main > form > nav {
    width: 80%;
  }
}
@media only screen and (max-width: 767px) {
  body > main > section,
  body > main > form > section,
  body > main > nav,
  body > main > form > nav {
    width: 83%;
  }
}
body > main > header,
body > main > form > header {
  position: relative;
  height: 48vh;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0;
}
body > main > header > h1,
body > main > form > header > h1,
body > main > header > h2,
body > main > form > header > h2 {
  color: #EBEBEB;
}
form.sprout {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  text-align: left;
}
form.sprout input[type="text"],
form.sprout input[type="email"],
form.sprout input[type="tel"] {
  display: inline-block;
  width: 100%;
  outline: none;
  border: 0;
  border-bottom: 1px solid #323232;
  padding: 0.5em 0;
}
form.sprout > label {
  box-sizing: border-box;
  flex: 1 auto;
  display: flex;
  min-width: 40%;
  margin-right: 0.5em;
  margin-left: 0.5em;
  padding-bottom: 0.5em;
  white-space: nowrap;
  position: relative;
}
form.sprout label[data-required]:after {
  content: '*';
  color: #009CDA;
  display: flex;
  flex-direction: column;
  margin-left: -0.5em;
  margin-top: 0.5em;
  margin-bottom: auto;
}
form.sprout h3 {
  text-align: left;
  flex: 1 100%;
}
form.sprout > div,
form.sprout > label > div {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  text-align: left;
  margin-top: 0;
  flex: 1 100%;
}
form.sprout > div > label,
form.sprout > label > div > label {
  flex: 1 0;
  min-width: 30%;
}
form.sprout > div > label:last-child:nth-child(even),
form.sprout > label > div > label:last-child:nth-child(even) {
  flex: 2 2;
}
form.sprout > div > label input,
form.sprout > label > div > label input {
  margin: 0;
  padding: 0;
  vertical-align: text-bottom;
}
form.sprout label[for="fields-messageField"] {
  flex: 1 100%;
  border: 0;
  padding: 0;
  border-bottom: 1px solid #323232;
}
form.sprout label[for="fields-messageField"] textarea {
  border: 0;
  outline: none;
  display: inline-block;
  box-sizing: border-box;
  padding: 0.5em 0;
  width: 100%;
  resize: none;
}
form.sprout label[for="fields-messageField"] textarea + .counter {
  position: absolute;
  right: 0;
  bottom: -1.5em;
  color: #009CDA;
}
form.sprout input[type="submit"] {
  margin-right: auto;
  margin-left: auto;
}
form.sprout .instructions {
  padding-top: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  color: #009CDA;
}
#tooltip {
  position: absolute;
  color: white;
  background-color: #323232;
  width: auto;
  border-radius: 0.5em;
  padding: 1em;
  z-index: 500;
  padding-bottom: 3.5em;
  pointer-events: none;
  transition: opacity 0.5s ease;
  will-change: opacity;
  opacity: 0;
  display: none;
}
#tooltip.active {
  display: block;
  opacity: 1;
  pointer-events: auto;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.5);
}
#tooltip button {
  position: absolute;
  left: 0;
  background-color: #97be0d;
  padding: 1em;
  bottom: 0;
  z-index: 1;
  margin: 0;
  border: 0;
  right: 0;
  color: white;
  width: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 0.5em;
  white-space: nowrap;
}
#tooltip:after {
  content: '';
  position: absolute;
  bottom: -0.5em;
  left: calc(50% - 0.5em);
  background-color: #97be0d;
  height: 1em;
  width: 1em;
  transform: rotate(45deg);
}
#modal,
.overlay {
  position: fixed;
  top: 0 ;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: -1;
  opacity: 0;
  display: none;
  margin-top: 0;
  transition: opacity 0.2s linear;
  pointer-events: none;
  will-change: opacity;
}
#modal.active,
.overlay.active {
  opacity: 1;
  z-index: 9999;
  pointer-events: auto;
  display: flex;
}
#modal > section,
.overlay > section {
  min-width: 50%;
  max-width: 80%;
  background-color: white;
  position: relative;
  margin: 0;
}
@media only screen and (max-width: 1024px) {
  #modal > section,
  .overlay > section {
    max-width: 100vw;
    width: 80%;
  }
}
@media only screen and (max-width: 767px) {
  #modal > section,
  .overlay > section {
    width: 90%;
  }
}
#modal > section > article,
.overlay > section > article {
  text-align: center;
  display: flex;
  min-height: 50%;
  padding: 3em 2em;
  flex-direction: column;
  justify-content: center;
}
#modal > section form label,
.overlay > section form label {
  padding: 0.5em;
}
#modal > section form > div,
.overlay > section form > div,
#modal > section form > h3,
.overlay > section form > h3 {
  margin-right: 1em;
  margin-left: 1em;
}
#modal > section form > div,
.overlay > section form > div {
  margin-top: 1em;
}
#modal > section form input[type=text],
.overlay > section form input[type=text],
#modal > section form input[type=email],
.overlay > section form input[type=email] {
  appearance: none;
  border: 0;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid #009CDA;
  color: #009CDA;
  outline: none;
  text-align: left;
}
#modal > section form input[type=submit],
.overlay > section form input[type=submit] {
  margin-top: 1em;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: #009CDA;
  color: white;
}
body.resources #modal > section,
body.resources .overlay > section {
  background: transparent;
}
#modal button.close,
.overlay button.close {
  padding: 0.5em 1em;
  line-height: 1;
  background-color: #009CDA;
  color: white;
  border: none;
  margin-top: auto;
  margin-left: auto;
  margin-right: 0;
  border-radius: 0;
  position: absolute;
  right: 0;
  top: -2em;
}
@media only screen and (max-width: 767px) {
  #modal,
  .overlay {
    padding-top: 3em;
  }
  #modal > section,
  .overlay > section {
    margin-bottom: 0;
    margin-top: 0;
  }
  #modal > section > article,
  .overlay > section > article {
    max-width: 100vw;
  }
  #modal > section > article > form > div,
  .overlay > section > article > form > div {
    overflow-y: auto;
    overflow-scrolling: touch;
    max-height: 30vh;
  }
  #modal > section > article > form input[type=submit],
  .overlay > section > article > form input[type=submit] {
    margin-top: 1em ;
  }
  #modal .field-countries,
  .overlay .field-countries {
    max-height: 34vh;
    overflow-scrolling: touch;
    overflow-y: auto;
  }
}
section.countries > header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2em 0;
}
section.countries > header > h2 {
  margin-top: 0;
  margin-right: 20px;
}
section.countries > header > button:not(.add) {
  margin: 0;
}
@media (max-width: 1023px) {
  section.countries > header {
    flex-direction: column;
  }
  section.countries > header > h2 {
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
}
section.countries > label,
section.countries div > label {
  display: inline-block;
  text-align: center;
  color: #323232;
  vertical-align: top;
  position: relative;
  box-sizing: content-box;
  margin: 1em 0;
  overflow: visible;
  padding-top: 6em;
  width: 8em;
}
section.countries > label.disabled,
section.countries div > label.disabled {
  opacity: 0.2;
}
section.countries > label > .language,
section.countries div > label > .language {
  position: absolute;
}
section.countries button.add {
  margin-top: 0;
  border-radius: 50%;
  border: 1px dashed #009CDA;
  position: relative;
  width: 5em;
  height: 5em;
  margin-left: 2em;
  margin-right: 2em;
  transition: box-shadow 0.2s ease;
  display: inline-block;
}
section.countries button.add:hover {
  background-color: inherit;
  color: inherit;
}
section.countries button.add:after,
section.countries button.add:before {
  content: '';
  background-color: #009CDA;
  position: absolute;
  top: 1.4em;
  left: 1.4em;
  width: 2em;
  height: 2em;
  overflow: hidden;
}
section.countries button.add:after {
  height: 2px;
  margin: 1em 1px;
}
section.countries button.add:before {
  width: 2px;
  margin: 1px 1em;
}
section.countries button:not(.add) {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
section.countries input[type=checkbox] {
  display: none;
}
section.countries button.add {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
}
section.countries label > span,
section.countries label > button {
  position: absolute;
  height: 5em;
  width: 5em;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: block;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  outline: none;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  border-radius: 50%;
}
section.countries .l-locales {
  transition: height 200ms, width 200ms;
  height: 70px;
  width: 70px;
  z-index: 5;
  position: absolute;
  display: flex;
}
section.countries .l-locales > button {
  position: relative;
  z-index: 2;
  opacity: 0;
  font-family: icomoon, sans-serif;
  color: white;
  background-color: #323232;
  width: 70px;
  height: 70px;
  max-width: 70px;
  max-height: 70px;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  flex-basis: 70px;
  border: 0;
  border-radius: 0;
}
section.countries .l-locales > button:first-child {
  border-bottom-left-radius: 70px;
  border-top-left-radius: 70px;
}
section.countries .l-locales > button:last-child {
  border-bottom-right-radius: 70px;
  border-top-right-radius: 70px;
}
section.countries .l-locales > .selection {
  transition: height 500ms, width 500ms, background-color 200ms;
  position: absolute;
  z-index: 1;
  height: auto;
  width: auto;
  min-width: 42px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-basis: 50%;
  bottom: 0.5em;
  left: 50%;
  transform: translateX(-50%);
}
section.countries .l-locales > .selection > button {
  transition: height 500ms, width 500ms, background-color 200ms;
  color: white;
  padding: 3px 4px;
  margin: 0;
  background-color: #323232;
  width: 50%;
  font-size: 90%;
  border: 0;
  border-radius: 0;
  line-height: 1;
  outline: none;
}
section.countries .l-locales > .selection > button:first-child {
  border-radius: 3px 0 0 3px;
}
section.countries .l-locales > .selection > button:last-child {
  border-radius: 0 3px 3px 0;
}
section.countries .l-locales > .selection > button.checked {
  background-color: #97be0d;
  color: white;
}
section.countries .l-icon {
  box-sizing: border-box;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  z-index: 1;
}
section.countries .l-check {
  font-family: icomoon;
  font-size: 20px;
  line-height: 1.5em;
  border-color: #323232;
  background-color: #323232;
  top: -5px;
  right: -60px;
  height: 1.5em;
  width: 1.5em;
  max-width: none;
  min-width: initial;
  opacity: 0;
  transform: scale(0.1);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  z-index: 4;
}
section.countries .l-check:after {
  content: '\e90b';
  color: white;
}
section.countries .l-border {
  background-color: #323232;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  z-index: 0;
}
section.countries .l-shadow {
  box-sizing: border-box;
  filter: blur(5px);
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  opacity: 0;
  top: 8px;
  z-index: -1;
}
section.countries :checked ~ .l-icon {
  transform: none;
}
section.countries :checked ~ .l-border {
  transform: scale(1.2);
  opacity: 1;
}
section.countries :checked ~ .l-check {
  opacity: 1;
  transform: scale(1);
}
section.countries :checked ~ .l-shadow {
  opacity: 0;
}
section.countries label:hover:not(.split) :not(:checked) ~ .l-icon {
  transform: scale(1.05);
}
@media (min-width: 1024px) {
  section.countries label:hover:not(.split) .l-border {
    transform: scale(1.2);
    opacity: 1;
  }
}
section.countries label:hover:not(.split) .l-shadow {
  transform: scale(1.05);
  opacity: 0.9;
}
section.countries label.split:hover .l-border,
section.countries label.split:hover .l-shadow {
  opacity: 0;
}
section.countries .split > span.l-check,
section.countries .split > span.l-border,
section.countries .split > span.l-icon {
  opacity: 0;
  z-index: -1;
}
section.countries .split > .l-locales {
  opacity: 1;
  pointer-events: auto;
  width: 140px;
  transform: translateX(-35px);
}
section.countries .split > .l-locales > button {
  opacity: 1;
}
section.countries .split > .l-locales > button.disabled {
  opacity: .25;
  cursor: default;
  pointer-events: none;
}
section.countries .split > .l-locales > .selection {
  position: static;
  display: flex;
  flex-direction: row;
  margin: auto;
  transform: none;
  border-radius: 0;
  flex-basis: 100%;
  height: 70px;
  padding: 0;
  border: 0;
  text-align: center;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}
section.countries .split > .l-locales > .selection > button {
  height: 70px;
  border-radius: 0;
}
section.countries .split > .l-locales > .selection > button:first-child {
  margin-right: 1px;
}
.disclaimer {
  font-size: 90%;
}
@supports (-ms-ime-align:auto) {
  section.countries .l-shadow {
    filter: none;
    top: 0;
    opacity: 0;
    transform: none;
    box-shadow: none;
  }
  section.countries label:hover .l-shadow {
    opacity: 1;
    transform: scale(1.05);
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.9);
  }
}
@media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  section.countries .l-shadow {
    filter: none;
    top: 0;
    opacity: 0;
    transform: none;
    box-shadow: none;
  }
  section.countries label:hover .l-shadow {
    opacity: 1;
    transform: scale(1.05);
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.9);
  }
}
section.category.compact article {
  border: 2px solid #009CDA;
  background-color: white;
  transition: height 0.5s ease-in-out;
}
section.category.compact article:empty {
  display: none;
}
section.category.compact article div {
  margin-top: 0;
  border: 0;
}
section.category.compact article div + div {
  border-top: 1px solid #009ddd;
}
section.category.compact article:not(:empty) + article {
  position: relative;
}
section.category.compact article:not(:empty) + article:before {
  content: '';
  position: absolute;
  height: 5em;
  top: -5em;
  left: 50%;
  display: block;
  width: 5px;
  margin-left: -3px;
  z-index: -1;
  background-color: #009CDA;
}
section.category.compact .question {
  transition: opacity 0.5s ease, filter 0.5s ease;
}
section.category.compact .question:not(.selected) {
  pointer-events: none;
  opacity: 0.8;
  filter: grayscale(100%) blur(2px);
}
section.category.compact .question.selected {
  background-color: white;
  color: #323232;
  border-color: #009CDA;
  pointer-events: auto;
}
section.category.compact .question.selected i {
  color: #009CDA;
}
section.category.compact .question.selected label {
  color: #009CDA;
  border-color: #009CDA;
}
section.category.compact .question:hover {
  background-color: white;
}
section.category.compact .question:hover i {
  background-color: white;
  color: #009CDA;
  border-color: #009CDA;
}
section.category.compact .question:hover label {
  color: #009CDA;
  border-color: #009CDA;
}
section.category.compact .add {
  min-height: 5em;
  border: 1px dashed #009CDA;
  line-height: 5em;
  vertical-align: middle;
  text-align: center;
  display: block;
  font-size: 20px;
  margin-top: 3em;
  border-radius: 5px;
}
section.category.compact .add:hover {
  color: #009CDA;
  background-color: inherit;
}
section.category h2 {
  font-weight: normal;
  color: #323232;
  text-align: left;
  text-transform: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
section.category h2 button {
  white-space: nowrap;
}
section.category .question {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: stretch;
  padding: 2em 1em;
  border: 1px solid #009CDA;
  transition-property: color, background-color, border-color;
  transition-duration: 0.2s, 0.2s, 0.2s;
  transition-timing-function: ease,ease,ease;
}
section.category .question mark.hit {
  background-color: yellow;
}
section.category .question > * {
  margin: 0;
}
section.category .question i {
  margin-right: 1em;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  line-height: 3em;
  display: block;
  min-width: 3em;
  min-height: 3em;
  padding: 0;
  box-sizing: border-box;
  color: #009CDA;
}
@media only screen and (max-width: 767px) {
  section.category .question i {
    font-size: 14px;
  }
}
section.category .question p {
  text-align: left;
  border-left: 1px solid #009CDA;
  margin-right: auto;
  padding-left: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
section.category .question input[type="checkbox"] {
  display: none;
}
section.category .question label {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 2em;
  margin-right: 2em;
  border: none;
  border-radius: 0;
  position: relative;
  min-width: 2em;
  height: 2em;
  outline: none;
  transform-origin: center center;
  transition: transform 0.5s linear;
  will-change: transform;
  box-sizing: content-box;
}
section.category .question label:after,
section.category .question label:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  border: 1px solid #009CDA;
  overflow: hidden;
}
section.category .question label:after {
  height: 100%;
  margin-left: 1em;
  margin-right: 1em;
}
section.category .question label:before {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}
@media only screen and (max-width: 767px) {
  section.category .question {
    padding: 1em;
  }
  section.category .question label {
    display: none;
  }
}
section.category .question:hover {
  border-color: #97be0d;
  background-color: white;
}
section.category .question:hover i {
  color: white;
  background-color: #97be0d;
}
section.category .question:hover i,
section.category .question:hover p {
  border-color: #97be0d;
}
section.category .question:hover label:after,
section.category .question:hover label:before {
  border-color: #97be0d;
}
section.category .question.selected {
  background-color: #97be0d;
  color: white;
  border-color: white;
}
section.category .question.selected i {
  background-color: transparent;
  color: inherit;
  border-color: inherit;
}
section.category .question.selected p {
  border-color: inherit;
  color: inherit;
}
section.category .question.selected label {
  transform: rotate(45deg);
}
section.category .question.selected label:after,
section.category .question.selected label:before {
  border-color: inherit;
}
section.category .question.selected:hover label:after,
section.category .question.selected:hover label:before {
  border-color: #e95e28;
}
body.report header,
body.hub header {
  box-sizing: border-box;
}
body.report header .indicator,
body.hub header .indicator {
  margin-left: auto;
  margin-right: auto;
  color: white;
  border-color: white;
}
body.report header .scrollmagic-pin-spacer,
body.hub header .scrollmagic-pin-spacer {
  width: 100%;
  max-width: 100%;
}
body.report section h2,
body.hub section h2 {
  font-size: 150%;
  font-weight: 500;
  color: #323232;
  overflow: hidden;
  vertical-align: middle;
  text-transform: uppercase;
}
body.report section h2 > button,
body.hub section h2 > button {
  font-size: 70%;
  float: right;
  margin-right: 0;
  margin-left: 1em;
  padding: 0.5em 1em;
  line-height: 1;
}
body.report section.compact h2,
body.hub section.compact h2 {
  text-align: center;
  display: block;
}
@media only screen and (max-width: 767px) {
  body.report section.compact .add,
  body.hub section.compact .add {
    margin-top: 2em;
  }
}
body.report section.countries,
body.hub section.countries {
  text-align: center;
}
body.report main > footer,
body.hub main > footer,
body.report main > form > footer,
body.hub main > form > footer {
  padding-top: 3em;
  padding-bottom: 5em;
  background-color: #009ddd;
  color: #EBEBEB;
  display: flex;
  flex-direction: row;
}
body.report main > footer h2,
body.hub main > footer h2,
body.report main > form > footer h2,
body.hub main > form > footer h2 {
  color: white;
}
body.report main > footer > section,
body.hub main > footer > section,
body.report main > form > footer > section,
body.hub main > form > footer > section {
  max-width: 50%;
  margin: 0 auto;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  body.report main > footer > section,
  body.hub main > footer > section,
  body.report main > form > footer > section,
  body.hub main > form > footer > section {
    max-width: none;
  }
}
body.report main > footer img,
body.hub main > footer img,
body.report main > form > footer img,
body.hub main > form > footer img {
  max-width: 100%;
}
body.report main > footer .group-by label,
body.hub main > footer .group-by label,
body.report main > form > footer .group-by label,
body.hub main > form > footer .group-by label {
  cursor: pointer;
  display: inline-block;
  padding: 0.5em 2em;
  border: 1px solid white;
  color: white;
  border-radius: 5px;
  outline: none;
  margin-left: 1em;
  margin-right: 1em;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
}
body.report main > footer .group-by label input[type=radio],
body.hub main > footer .group-by label input[type=radio],
body.report main > form > footer .group-by label input[type=radio],
body.hub main > form > footer .group-by label input[type=radio] {
  display: none;
}
body.report main > footer .group-by label.checked,
body.hub main > footer .group-by label.checked,
body.report main > form > footer .group-by label.checked,
body.hub main > form > footer .group-by label.checked {
  background-color: white;
  color: #009CDA;
}
body.report main {
  margin-bottom: 0;
}
body.report nav.secondary {
  height: 3.5em;
  justify-content: space-between;
}
body.report nav.secondary.report.empty {
  display: none;
}
body.report nav.secondary:empty {
  display: none;
}
body.report nav.secondary > * {
  display: flex;
  align-items: center;
}
body.report nav.secondary .selected {
  max-width: 50%;
  overflow: hidden;
  margin-right: auto;
}
body.report nav.secondary .selected span {
  margin-left: 1em;
}
body.report nav.secondary .selected button {
  border-width: 1px;
  border-radius: 50%;
  border-style: solid;
  border-color: #009CDA;
  height: 2.5em;
  width: 2.5em;
  padding: 0;
  font-style: normal;
  text-align: center;
  vertical-align: baseline;
  text-transform: uppercase;
  line-height: 2.5em;
  font-size: 25px;
  font-size: 12px;
  color: #EBEBEB;
  line-height: 3em;
  height: 3em;
  width: 3em;
  border-color: white;
  margin-right: 0.5em;
  pointer-events: none;
}
@media only screen and (max-width: 767px) {
  body.report nav.secondary .selected {
    display: none;
  }
}
body.report nav.secondary .selected:first-child {
  background: transparent;
}
body.report nav.secondary > :last-child {
  margin: 0 -1em;
  padding: 0 1em;
  height: 100%;
  box-sizing: border-box;
}
body.report nav.secondary > div {
  align-self: center;
  color: white;
  font-size: 16px;
}
@media only screen and (max-width: 767px) {
  body.report nav.secondary > div {
    display: none;
  }
}
body.report nav.secondary [data-rel="reset"],
body.report nav.secondary [data-rel="save"] {
  margin-left: auto;
}
body.report.category.all form header h1 {
  font-size: 3em;
}
body.questions nav.bottom {
  transition: transform 200ms ease;
  display: block;
  position: fixed;
  margin: 0;
  padding: 0;
  bottom: 0;
  background-color: #009CDA;
  color: white;
  width: 100%;
  z-index: 10;
}
body.questions nav.bottom > a {
  display: block;
  padding: 1em;
  color: #EBEBEB;
}
body.questions nav.bottom.hide {
  transform: translateY(100%);
}
body.questions header > p {
  color: white;
}
body.questions header:not(:first-child) {
  margin-top: 2em;
}
body.questions input#questions-search {
  display: block;
  border: 1px solid #767676;
  border-radius: 5px;
  width: 100%;
  max-width: calc(710px - 8em - 8px);
  margin-left: auto;
  margin-right: auto;
  padding: 1em 1.5em;
  outline: none;
  text-align: left;
  position: relative;
  box-sizing: border-box;
}
body.questions input#questions-search + label {
  margin-top: 1em;
  display: block;
  min-height: 1em;
}
body.hub.questions.preview .nag.report,
body.hub.questions.all .nag.report {
  display: flex;
}
body.hub.data-analysis section.indicators > article,
body.hub.indicators section.indicators > article {
  border: 1px solid #009ddd;
  padding: 1.5em;
}
body.hub.data-analysis section.indicators > article header,
body.hub.indicators section.indicators > article header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0 solid #009CDA;
  padding-bottom: 0;
}
body.hub.data-analysis section.indicators > article header > *,
body.hub.indicators section.indicators > article header > * {
  margin-top: auto;
  margin-bottom: auto;
}
body.hub.data-analysis section.indicators > article header h2,
body.hub.indicators section.indicators > article header h2 {
  flex-shrink: 0;
  display: inline-block;
  color: #009CDA;
  border-right: 1px solid #009CDA;
  padding: 1em;
  margin-right: 1em;
  padding-left: 0;
}
@media only screen and (max-width: 767px) {
  body.hub.data-analysis section.indicators > article header h2,
  body.hub.indicators section.indicators > article header h2 {
    font-size: 14px;
  }
}
body.hub.data-analysis section.indicators > article header h1,
body.hub.indicators section.indicators > article header h1 {
  position: relative;
  text-align: left;
  flex-grow: 1;
  color: #323232;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.1;
}
@media only screen and (max-width: 1024px) {
  body.hub.data-analysis section.indicators > article header h1,
  body.hub.indicators section.indicators > article header h1 {
    margin-right: 30px;
  }
}
@media only screen and (max-width: 767px) {
  body.hub.data-analysis section.indicators > article header h1,
  body.hub.indicators section.indicators > article header h1 {
    font-size: 14px;
  }
}
@media (max-width: 468px) {
  body.hub.data-analysis section.indicators > article header h1,
  body.hub.indicators section.indicators > article header h1 {
    margin-right: 0;
  }
}
body.hub.data-analysis section.indicators > article header button,
body.hub.indicators section.indicators > article header button {
  padding: 13px;
  margin-left: 0;
  margin-right: 0;
  font-size: 150%;
  border: 0;
  transition: transform 0.5s ease;
  cursor: pointer;
  background: url(/assets/misc/chevron.png) no-repeat 0 50%;
  background-size: contain;
}
body.hub.data-analysis section.indicators > article header button:hover,
body.hub.indicators section.indicators > article header button:hover {
  background-color: inherit;
  color: inherit;
}
@media only screen and (max-width: 767px) {
  body.hub.data-analysis section.indicators > article header button,
  body.hub.indicators section.indicators > article header button {
    padding: 0.5em;
  }
}
body.hub.data-analysis section.indicators > article p,
body.hub.indicators section.indicators > article p {
  display: none;
  text-align: left;
}
body.hub.data-analysis section.indicators > article.open,
body.hub.indicators section.indicators > article.open {
  padding-bottom: 2em;
}
body.hub.data-analysis section.indicators > article.open header,
body.hub.indicators section.indicators > article.open header {
  border-bottom-width: 2px;
  padding-bottom: 1.5em;
}
body.hub.data-analysis section.indicators > article.open button,
body.hub.indicators section.indicators > article.open button {
  transform: rotate(180deg);
}
body.hub nav.secondary :last-child {
  margin-right: auto;
}
body.hub nav.secondary :first-child {
  margin-left: auto;
}
@media only screen and (max-width: 767px) {
  body.hub nav.secondary {
    max-height: 3.5em;
    display: block;
    will-change: max-height;
  }
  body.hub nav.secondary :last-child {
    margin-right: 3em;
  }
  body.hub nav.secondary:after {
    content: '⌵';
    color: white;
    font-weight: bold;
    position: absolute;
    right: 2em;
    top: 1.2em;
    transition: transform 0.2s ease-in;
    will-change: transform;
    transform: rotate(180deg);
  }
  body.hub nav.secondary > a {
    padding: 1em;
    padding-right: 0;
    padding-left: 3em;
    margin-right: 3em;
    display: block;
    pointer-events: none;
  }
  body.hub nav.secondary > a:not(.active) {
    display: none;
  }
  body.hub nav.secondary.open {
    max-height: none;
  }
  body.hub nav.secondary.open:after {
    transform: rotate(0deg);
  }
  body.hub nav.secondary.open > a {
    position: relative;
    display: block;
    pointer-events: auto;
    margin-right: 3em;
  }
}
body.hub header > nav :first-child {
  margin-left: auto;
}
body.hub header > nav :last-child {
  margin-right: auto;
}
body.hub .question {
  pointer-events: none;
}
body.hub .question h2 {
  text-align: left;
}
body.homepage {
  transition: background-color 0.2s ease;
  will-change: background-color;
}
body.homepage > nav.main {
  transition: background-color 0.2s ease, padding-top 0.5s ease;
  will-change: padding-top, background-color;
}
body.homepage > nav.main a {
  color: #323232;
  will-change: color;
  transition: color 0.5s ease;
}
body.homepage > nav.main a > img.alt {
  display: none;
}
body.homepage > nav.main .privacy {
  display: none;
}
body.homepage > nav.main.alt {
  background-color: transparent;
  padding-top: 2.5em;
}
@media only screen and (max-width: 767px) {
  body.homepage > nav.main.alt {
    padding-top: 0;
  }
}
body.homepage > nav.main.alt .privacy {
  position: absolute;
  display: flex;
  top: 1em;
  right: 2.5em;
}
body.homepage > nav.main.alt .privacy > a {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
body.homepage > nav.main.alt a {
  color: white;
}
body.homepage > nav.main.alt a > img {
  display: none;
}
body.homepage > nav.main.alt a > img.alt {
  display: block;
}
body.homepage > nav.main.alt .hamburger:not(.is-active) > span > span {
  background-color: #EBEBEB;
}
body.homepage > nav.main.alt .hamburger:not(.is-active) > span > span:before,
body.homepage > nav.main.alt .hamburger:not(.is-active) > span > span:after {
  background-color: #EBEBEB;
}
@media only screen and (max-width: 1024px) {
  body.homepage > nav.main.open {
    background-color: white;
  }
  body.homepage > nav.main.open a {
    color: #323232;
  }
  body.homepage > nav.main.open a > img {
    filter: none;
  }
}
body.homepage.dark h1,
body.homepage.dark h2,
body.homepage.dark p {
  color: rgba(255, 255, 255, 0.8);
}
body.homepage svg {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
}
body.homepage svg > * {
  margin-top: 0;
}
body.homepage svg path {
  stroke: rgba(255, 255, 255, 0.5);
  stroke-width: 10;
  fill: none;
  stroke-linejoin: miter;
  stroke-linecap: square;
  vector-effect: non-scaling-stroke;
}
body.homepage main {
  padding-bottom: 0;
  margin-bottom: 0;
}
body.homepage main h1 {
  text-transform: uppercase;
}
body.homepage main > .scrollmagic-pin-spacer {
  overflow: hidden;
  max-width: 100vw;
  max-height: 100vh;
}
body.homepage main > header {
  height: 100vh;
  box-sizing: border-box;
}
@media only screen and (max-width: 767px) {
  body.homepage main > header {
    height: auto;
    min-height: 100vh;
    padding-top: 10vh;
    padding-bottom: 15vh;
  }
}
body.homepage main > header h1,
body.homepage main > header h2,
body.homepage main > header p {
  color: #EBEBEB;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1024px) {
  body.homepage main > header h1,
  body.homepage main > header h2,
  body.homepage main > header p {
    width: auto;
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (max-width: 767px) {
  body.homepage main > header h1,
  body.homepage main > header h2,
  body.homepage main > header p {
    width: calc(100vw - 2em);
    padding: 0;
  }
}
body.homepage main > header section {
  height: auto;
}
body.homepage main > header section .button:not(.scroll-down) {
  width: 214px;
  margin-top: 0;
  padding: .50em 0;
  font-size: 18px;
  line-height: 1.15;
  font-weight: 300;
}
@media (max-width: 786px) {
  body.homepage main > header section .button:not(.scroll-down) {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  body.homepage main > header section .button:not(.scroll-down) + .button {
    margin-top: 1em;
  }
}
body.homepage main > header .icon.scroll-down {
  padding: 0;
  color: white;
  border-color: white;
  border: none;
  margin: 0 auto;
  font-size: 3em;
  position: absolute;
  bottom: 2.5vh;
  left: calc(50vw - 22px);
  cursor: pointer;
}
body.homepage main > header .icon.scroll-down:hover {
  color: white;
  background-color: transparent;
}
@media (max-width: 1024px) {
  body.homepage main > header .icon.scroll-down {
    bottom: 6vh;
  }
}
@media (max-width: 374px) {
  body.homepage main > header .icon.scroll-down {
    display: none;
  }
}
body.homepage main > section {
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0;
  height: 50vh;
  position: relative;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  body.homepage main > section {
    height: 90vh;
  }
}
@media only screen and (max-width: 767px) {
  body.homepage main > section.about-section-11 form {
    padding-bottom: 60px;
  }
}
body.homepage main > section h1 {
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;
  background-color: inherit;
  position: relative;
  will-change: background-color;
  transition: background-color 0.2s ease;
  z-index: 1;
}
body.homepage main > section > article,
body.homepage main > section > form {
  top: 47.5%;
  position: relative;
  margin-top: 0;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  background-color: inherit;
}
body.homepage main > section > article h1,
body.homepage main > section > form h1 {
  display: inline-block;
  text-align: left;
  padding-right: 1em;
  padding-left: 1em;
}
@media only screen and (max-width: 767px) {
  body.homepage main > section > article h1,
  body.homepage main > section > form h1 {
    padding: 1em;
  }
}
@media only screen and (max-width: 767px) {
  body.homepage main > section > article,
  body.homepage main > section > form {
    max-width: 100%;
    width: auto;
    margin-left: 1em;
    margin-right: 1em;
  }
}
body.homepage main > section > article + form {
  padding-top: 0;
}
body.homepage main > section > p {
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
  background-color: inherit;
}
@media only screen and (max-width: 767px) {
  body.homepage main > section h1,
  body.homepage main > section h2,
  body.homepage main > section p {
    margin-left: auto;
    margin-right: auto;
    padding-top: 0;
    margin-top: 0;
    padding-left: 1em;
    padding-right: 1em;
    text-align: center;
  }
}
body.homepage main > section.white.top.pull {
  background-color: #ffffff;
}
body.homepage main > section.white.top.pull h1 {
  display: inline-block;
}
body.homepage main > section.white:not(.top):not(.pull) {
  background-color: white;
}
body.homepage main > section.white:not(.top):not(.pull) h1,
body.homepage main > section.white:not(.top):not(.pull) h2 {
  background-color: white;
}
@media only screen and (max-width: 767px) {
  body.homepage main > section.white:not(.top):not(.pull).right p {
    margin-top: 0;
    margin-right: 2em;
  }
}
body.homepage main > section.embed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
body.homepage main > section.embed .play {
  margin-top: 0;
  margin-bottom: 0;
  width: 15vw;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  padding: 0;
  border: 0;
  max-width: 8%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1;
  transition: transform 0.2s ease;
  will-change: transform;
}
@media only screen and (max-width: 1024px) {
  body.homepage main > section.embed .play {
    max-width: 10%;
  }
}
@media only screen and (max-width: 767px) {
  body.homepage main > section.embed .play {
    max-width: 15%;
  }
}
body.homepage main > section.embed .play img {
  width: 100%;
}
body.homepage main > section.embed .play:hover {
  background-color: transparent;
  color: white;
  transform: translate(-50%, -50%) scale(1.1);
}
@media only screen and (max-width: 767px) {
  body.homepage main > section.embed .play {
    width: 40px;
  }
}
body.homepage main > section.embed > .player {
  flex-direction: column;
  max-width: 100vw;
  max-height: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}
body.homepage main > section.embed > .player .close {
  margin-top: auto;
  margin-left: auto;
  display: none;
}
body.homepage main > section.embed > .player iframe {
  width: 100%;
  height: 100%;
  max-height: 100%;
  margin-top: 0;
}
body.homepage main > section.embed,
body.homepage main > section.image {
  width: auto;
  padding-top: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
body.homepage main > section.embed,
body.homepage main > section.image,
body.homepage main > section.loop {
  height: 100vh;
}
body.homepage main > section.pull.right {
  height: 50vh;
}
body.homepage main > section.pull.right > article {
  position: relative;
  margin-right: 0;
  margin-left: 0;
  left: 35%;
  top: 14%;
  max-width: 52%;
  margin-top: 0;
  text-align: left;
  padding-left: 14px;
  padding-right: 14px;
  box-sizing: border-box;
}
@media only screen and (max-width: 767px) {
  body.homepage main > section.pull.right > article {
    max-width: none;
    display: block;
    margin-left: auto;
    padding-left: 0;
    left: 0;
    padding-right: 0;
    text-align: center;
    width: auto;
  }
  body.homepage main > section.pull.right > article h1 {
    padding-top: 1em;
  }
  body.homepage main > section.pull.right > article p {
    padding-right: 5em;
  }
}
body.homepage main > section.pull.bottom {
  height: 25vh;
  padding-top: 15vh;
  padding-bottom: 5vh;
}
body.homepage main > section:last-child {
  height: 75vh;
}
body.homepage main > section:last-child > article,
body.homepage main > section:last-child > form {
  top: 22.5%;
}
@media only screen and (max-width: 1440px) {
  body.homepage main > section > article,
  body.homepage main > section > form {
    top: 45.5%;
  }
  body.homepage main > section:last-child {
    top: 21.5%;
  }
  body.homepage main > section.pull.right > article {
    top: 10.5%;
  }
  body.homepage main > section.loop > article h1 {
    top: 3%;
  }
}
@media only screen and (max-width: 1024px) {
  body.homepage main > section > article,
  body.homepage main > section > form {
    top: 45%;
    width: 70%;
  }
  body.homepage main > section:last-child {
    top: 21%;
  }
  body.homepage main > section.pull.right > article {
    top: 10%;
  }
}
@media only screen and (max-width: 767px) {
  body.homepage main > section > article,
  body.homepage main > section > form {
    top: 44%;
    width: 90%;
  }
  body.homepage main > section.pull.top article {
    padding-top: 17vh;
    z-index: 1;
  }
  body.homepage main > section.pull.top h1 {
    font-size: 38px;
    padding: 0;
    width: auto;
    display: block;
  }
  body.homepage main > section.pull.right {
    height: 60vh;
  }
  body.homepage main > section.pull.right > article {
    top: 6%;
  }
  body.homepage main > section.left h1 {
    margin-right: 2em;
  }
  body.homepage main > section.left p {
    padding-left: 2em;
  }
}
body.homepage main section.loop {
  z-index: 0;
  height: 100vh;
}
@media only screen and (max-width: 767px) {
  body.homepage main section.loop {
    min-height: inherit;
    margin-top: 10vh;
  }
}
body.homepage main section.loop h1 {
  position: absolute;
  top: 3.5%;
  left: 5%;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  body.homepage main section.loop h1 {
    position: static;
    padding-bottom: 0.5em;
    margin-right: 2em;
    width: auto;
    padding-top: 0.3em;
  }
}
body.homepage main section.loop svg {
  z-index: -1;
}
body.homepage main section.loop > article,
body.homepage main section.loop > div > article {
  margin-left: auto;
  margin-right: auto;
}
body.homepage main section.loop > article > article,
body.homepage main section.loop > div > article > article {
  padding-top: 0;
  display: none;
}
body.homepage main section.loop > article > article.active,
body.homepage main section.loop > div > article > article.active,
body.homepage main section.loop > article > article.start,
body.homepage main section.loop > div > article > article.start,
body.homepage main section.loop > article > article.end,
body.homepage main section.loop > div > article > article.end {
  display: flex;
  position: absolute;
  top: 0;
  margin: 0 auto;
  width: 35%;
  left: -5%;
  right: -5%;
  padding: 15%;
  height: 70vh;
  flex-direction: column;
  justify-content: center;
}
body.homepage main section.loop > article > article.active > h2,
body.homepage main section.loop > div > article > article.active > h2,
body.homepage main section.loop > article > article.start > h2,
body.homepage main section.loop > div > article > article.start > h2,
body.homepage main section.loop > article > article.end > h2,
body.homepage main section.loop > div > article > article.end > h2 {
  font-size: 38px;
  flex-grow: 1;
  margin-top: 1em;
}
body.homepage main section.loop > article > article.active > p,
body.homepage main section.loop > div > article > article.active > p,
body.homepage main section.loop > article > article.start > p,
body.homepage main section.loop > div > article > article.start > p,
body.homepage main section.loop > article > article.end > p,
body.homepage main section.loop > div > article > article.end > p {
  font-size: 30px;
  margin-top: 0;
  flex-grow: 1.5;
  margin-left: auto;
  margin-right: auto;
  max-width: 35vw;
}
@media only screen and (max-width: 1024px) {
  body.homepage main section.loop > article > article.active > p,
  body.homepage main section.loop > div > article > article.active > p,
  body.homepage main section.loop > article > article.start > p,
  body.homepage main section.loop > div > article > article.start > p,
  body.homepage main section.loop > article > article.end > p,
  body.homepage main section.loop > div > article > article.end > p {
    font-size: 25px;
  }
}
@media only screen and (max-width: 767px) {
  body.homepage main section.loop > article > article.active > p,
  body.homepage main section.loop > div > article > article.active > p,
  body.homepage main section.loop > article > article.start > p,
  body.homepage main section.loop > div > article > article.start > p,
  body.homepage main section.loop > article > article.end > p,
  body.homepage main section.loop > div > article > article.end > p {
    flex-grow: 0;
    max-width: none;
  }
}
@media only screen and (min-width: 1921px) {
  body.homepage main section.loop > article > article.active,
  body.homepage main section.loop > div > article > article.active,
  body.homepage main section.loop > article > article.start,
  body.homepage main section.loop > div > article > article.start,
  body.homepage main section.loop > article > article.end,
  body.homepage main section.loop > div > article > article.end {
    width: 30%;
  }
}
@media only screen and (max-width: 767px) {
  body.homepage main section.loop > article > article.active,
  body.homepage main section.loop > div > article > article.active,
  body.homepage main section.loop > article > article.start,
  body.homepage main section.loop > div > article > article.start,
  body.homepage main section.loop > article > article.end,
  body.homepage main section.loop > div > article > article.end {
    width: auto;
  }
}
body.homepage main section.loop > article > section,
body.homepage main section.loop > div > article > section {
  position: absolute;
}
body.homepage main section.loop svg {
  margin-left: auto;
  margin-right: auto;
  overflow: visible;
  right: 0;
}
body.homepage main section.loop svg path {
  z-index: 1;
}
body.homepage main section.white svg path {
  stroke: rgba(0, 156, 218, 0.5);
}
body.homepage main form {
  z-index: 1;
  max-width: 60vw;
}
body.homepage main form label {
  flex: 1 0 40%;
}
body.contact main,
body.thank-you main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
}
@media only screen and (max-width: 1024px) {
  body.contact .sprout,
  body.thank-you .sprout {
    margin-left: 2em;
    margin-right: 2em;
  }
}
body.report-template {
  font-family: Arial, sans-serif;
  letter-spacing: normal;
  line-height: 1.5;
  font-size: 16px;
  color: black;
}
body.report-template * + * {
  margin-top: 1em;
  width: 100%;
}
body.report-template .primary {
  color: #009CDA;
}
body.report-template > ul {
  display: block;
  page-break-after: avoid;
  padding: 0;
}
body.report-template > ul li {
  margin-left: 1em;
  page-break-inside: avoid;
}
body.report-template > ul a {
  display: inline;
  margin: 0 0;
  padding: 0;
}
body.report-template main {
  box-sizing: border-box;
  min-height: 100vh;
  padding-right: 5em;
  overflow: visible;
}
body.report-template main,
body.report-template nav,
body.report-template header,
body.report-template > ul {
  text-align: left;
}
body.report-template main > h1,
body.report-template main > section > h1 {
  page-break-after: avoid;
  color: #009CDA;
  line-height: 2em;
  vertical-align: middle;
  display: flex;
}
body.report-template main > h1 strong,
body.report-template main > section > h1 strong {
  color: #323232;
}
body.report-template main.cover {
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
}
body.report-template main.cover > footer {
  position: absolute;
  bottom: 0;
  left: 1em;
  right: 1em;
  height: 15vh;
}
body.report-template main.cover > footer ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  margin-top: 0;
}
body.report-template main.cover > footer ul li {
  display: inline-block;
  flex-basis: 25%;
  margin: 0;
  flex-grow: 1;
  flex-shrink: 0;
}
body.report-template h1 > i {
  font-style: normal;
  width: 2em;
  height: 2em;
  display: inline-block;
  border: 1px solid #323232;
  background-position: center center;
  background-size: 100%;
  border-radius: 50%;
  margin-right: 0.5em;
}
body.report-template strong {
  color: black;
  font-weight: bold;
}
body.report-template hr {
  page-break-after: always;
  margin-bottom: 1em;
}
body.report-template p {
  font-family: Arial, sans-serif;
  page-break-inside: avoid;
}
body.report-template section {
  width: auto;
  box-sizing: border-box;
}
body.report-template section > h2 {
  color: #009CDA;
  font-size: 24px;
}
body.report-template section > h3 {
  color: #009CDA;
  font-weight: lighter;
}
body.report-template section > h4 {
  color: #323232;
  font-weight: bold;
  font-size: 16px;
}
body.report-template section > h4 > span {
  color: #009CDA;
  margin-bottom: 1em;
}
body.report-template section > h2,
body.report-template section > h3,
body.report-template section > h4 {
  overflow: hidden;
  margin-bottom: 1em;
  page-break-after: avoid;
  page-break-inside: avoid;
}
body.report-template table {
  box-sizing: border-box;
  width: 100%;
  page-break-inside: avoid;
  border: 1px solid ;
  border-collapse: collapse;
}
body.report-template table td,
body.report-template table th {
  page-break-inside: avoid;
  padding: 0.5em;
  vertical-align: middle;
  border: 1px solid #767676;
}
body.report-template table td > *,
body.report-template table th > * {
  margin: 0;
  padding: 0;
  display: inline;
}
body.report-template img {
  box-sizing: border-box;
  max-width: 100%;
  display: block;
  page-break-inside: avoid;
}
@media print {
  body.report-template {
    margin: 0 10mm;
  }
  body.report-template main {
    padding-right: 0;
  }
  body.report-template main h1 {
    align-items: center;
    line-height: 1em !important;
    vertical-align: top;
  }
  body.report-template main h1.chapter {
    align-items: flex-start;
  }
}
body.stream {
  background-color: #009CDA;
}
body.stream iframe {
  margin-top: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
body.stream main {
  background-image: url(/assets/bg_stream_outlines.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
}
body.stream main section {
  color: white;
  padding-top: 10vh;
}
body.stream main section > img {
  max-width: 100%;
}
body.stream main h1,
body.stream main h2,
body.stream main h3 {
  color: white;
  text-transform: uppercase;
}
body.stream main .play {
  display: none;
}
body.stream main .sketchfab-embed-wrapper {
  position: relative;
  padding-bottom: 66.5%;
  width: 100%;
}
body.stream main .player {
  margin-top: 3em;
  position: relative;
  padding-bottom: 66.5%;
  opacity: 1;
  display: block;
  z-index: auto;
}
body.stream main .player .close {
  display: none;
}
body.stream nav.main:not(.open) {
  background: #009CDA;
  position: fixed;
}
body.stream nav.main:not(.open) a,
body.stream nav.main:not(.open) .active,
body.stream nav.main:not(.open) a:hover,
body.stream nav.main:not(.open) a:visited {
  color: white;
}
body.stream nav.main .hamburger:not(.is-active) > span > span {
  background-color: #EBEBEB;
}
body.stream nav.main .hamburger:not(.is-active) > span > span:before,
body.stream nav.main .hamburger:not(.is-active) > span > span:after {
  background-color: #EBEBEB;
}
body.stream nav.main .brand > img {
  display: none;
}
body.stream nav.main .brand > img.alt {
  display: block;
}
body > main > header.page-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding-top: 12rem;
  padding-bottom: 3rem;
}
@media (max-width: 767px) {
  body > main > header.page-header {
    padding-top: 8rem;
    padding-bottom: 2rem;
  }
}
body > main > header.page-header > h1 {
  margin: 0;
  font-size: 3rem;
  letter-spacing: .06em;
  text-align: center;
  color: #FFF;
}
@media (max-width: 767px) {
  body > main > header.page-header > h1 {
    font-size: 2.285rem;
  }
}
body > main > header.page-header > div {
  display: flex;
  align-items: center;
}
body > main > header.page-header > div > .button {
  margin: 0 1rem;
  padding: .5em 1.5em;
  font-size: 18px;
  line-height: 1.15;
  font-weight: 300;
  color: #FFF;
  border-color: #FFF;
}
@media (max-width: 767px) {
  body > main > header.page-header > div > .button {
    margin: 0 .25rem;
    font-size: 16px;
    color: #009CDA;
    background: #FFF;
  }
}
body > main > header.page-header:before {
  content: '';
  position: absolute;
  z-index: -100;
  top: 0;
  right: 0;
  left: 0;
  height: 620px;
  background-image: url('/assets/bg_stream_outlines_2.png'), linear-gradient(to right, #009cdb 0%, #4fbddb 100%);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}
@media (max-width: 1023px) {
  body > main > header.page-header:before {
    background-position: center top 120px, center top;
    background-size: 120%;
  }
}
@media (max-width: 767px) {
  body > main > header.page-header:before {
    background-position: left -100px top 80px, center top;
    background-size: 200%;
  }
}
body > main > article.page-container {
  width: 860px;
  max-width: 100%;
  margin: 3rem auto 2rem;
  padding: 30px;
  background-color: #FFF;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
}
@media (max-width: 1023px) {
  body > main > article.page-container {
    width: auto;
    margin-right: 2rem;
    margin-left: 2rem;
  }
}
@media (max-width: 767px) {
  body > main > article.page-container {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}
@media (max-width: 500px) {
  body > main > article.page-container {
    margin-right: 0;
    margin-left: 0;
  }
}
.hub.executive-summaries > main > article.page-container {
  padding-right: 20px;
  padding-left: 20px;
}
.resources main > article.page-container {
  z-index: 1;
  padding: 0 15px;
  background-color: transparent;
  box-shadow: none;
}
.resources main > article.page-container h2 {
  font-size: 42px;
  text-transform: uppercase;
  font-weight: 700;
}
@media (max-width: 400px) {
  .resources main > article.page-container h2 {
    font-size: 32px;
  }
}
.resources main > article.page-container > .sorting {
  margin-top: 1rem;
}
.resources main > article.page-container > .sorting,
.resources main > article.page-container > .sorting > div {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.resources main > article.page-container > .sorting > div {
  margin: 0;
}
.resources main > article.page-container > .sorting > div > button,
.resources main > article.page-container > .sorting > div > span {
  display: inline-block;
  margin: 0;
  padding: .4em .6em;
  font-family: 'Montreal', Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4;
  color: #323232;
  text-align: left;
  border: 1px solid transparent;
  background: #FFF;
  border-radius: 3px;
  transition: all 0.25s ease-out;
  white-space: nowrap;
}
.resources main > article.page-container > .sorting > div > span {
  margin-right: 1rem;
  cursor: default;
}
.resources main > article.page-container > .sorting > div > span .close {
  display: inline-block;
  margin-left: 4px;
  font-size: 18px;
  line-height: 1.3em;
  color: #9f9f9f;
  cursor: pointer;
  vertical-align: text-top;
  transition: all 0.25s ease-out;
}
.resources main > article.page-container > .sorting > div > span.video {
  color: #DC016B;
}
.resources main > article.page-container > .sorting > div > span.report {
  color: #455560;
}
.resources main > article.page-container > .sorting > div > span.presentation {
  color: #CBD302;
}
.resources main > article.page-container > .sorting > div > span.document {
  color: #009CDB;
}
.resources main > article.page-container > .sorting > div > span.disabled {
  background: transparent;
  border-color: #fff;
  color: #fff;
}
.resources main > article.page-container > .sorting > div > span.disabled > span {
  color: #fff;
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}
.resources main > article.page-container > .sorting > .dropdown {
  position: relative;
  margin-top: 1rem;
  margin-right: 1rem;
}
.resources main > article.page-container > .sorting > .dropdown > button {
  min-width: 160px;
  padding-right: 2em;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAJCAMAAADaUYZlAAAAQlBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACO4fbyAAAAFnRSTlMAAgYICg8RJCwtMDdZh56go6Wpq6ytgoQrBwAAAEVJREFUeNpNyNEOgCAMQ9E6NxGnMlH+/1cNsYncl+YUE8YWnGmgF1hbf4YAVjO5XdJHeeSq36+Pk2wOR7o7mcTeDENykC937AG7Rn+jrgAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: right .6em center;
}
.resources main > article.page-container > .sorting > .dropdown > nav {
  position: absolute;
  z-index: 9;
  top: 100%;
  left: 0;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #FFF;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  box-sizing: border-box;
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s ease-out;
}
.resources main > article.page-container > .sorting > .dropdown > nav > a {
  align-self: stretch;
  margin: 0;
  padding: .4em .6em;
  color: #323232;
  text-align: left;
}
.resources main > article.page-container > .sorting > .dropdown.open {
  box-shadow: 0 0 29px rgba(0, 0, 0, 0.2);
}
.resources main > article.page-container > .sorting > .dropdown.open > nav {
  opacity: 1;
  visibility: visible;
}
.resources main > article.page-container > .sorting > .dropdown.open > button {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.resources main > article.page-container > .sorting > .helper {
  display: none;
}
.resources main > article.page-container > .sorting > .helper > span {
  margin-top: 1rem;
}
.resources main > article.page-container .row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.resources main > article.page-container .row > div {
  width: 50%;
  margin: 0;
  padding: 15px;
  box-sizing: border-box;
}
@media (max-width: 767px) {
  .resources main > article.page-container .row > div {
    width: 100%;
  }
}
.resources main > article.page-container .row > div:not(:nth-last-child(2)):first-child {
  width: 100%;
}
@media (min-width: 768px) {
  .resources main > article.page-container .row > div:not(:nth-last-child(2)):first-child > .card > .card-thumb > div {
    padding-bottom: 40%;
  }
  .resources main > article.page-container .row > div:not(:nth-last-child(2)):first-child > .card > .card-body h3 {
    flex-direction: row;
  }
  .resources main > article.page-container .row > div:not(:nth-last-child(2)):first-child > .card > .card-body h3 span {
    margin-right: 1rem;
    margin-bottom: 0;
  }
}
.resources main > article.page-container > .categories {
  display: none;
}
.resources main > article.page-container > .categories.on {
  display: block;
}
.resources main > article.page-container > .categories > div {
  display: none;
}
@media (max-width: 400px) {
}
.resources main > article.page-container > .categories > div > h2 {
  margin: 1.2rem 0;
  text-align: left;
}
.resources main > article.page-container > .categories > div#video > h2 {
  color: #FFF;
}
.resources main > article.page-container > .categories > div#report > h2 {
  color: #455560;
}
.resources main > article.page-container > .categories > div#document > h2 {
  color: #009CDB;
}
.resources main > article.page-container > .categories > div#presentation > h2 {
  color: #CBD302;
}
.resources main > article.page-container#data-wrapper[data-sortby="category"] .helper {
  display: flex;
}
.resources main > article.page-container#data-wrapper[data-categories*="video"] > .categories > #video {
  display: block;
}
.resources main > article.page-container#data-wrapper[data-categories*="report"] > .categories > #report {
  display: block;
}
.resources main > article.page-container#data-wrapper[data-categories*="document"] > .categories > #document {
  display: block;
}
.resources main > article.page-container#data-wrapper[data-categories*="presentation"] > .categories > #presentation {
  display: block;
}
.resources main > article.page-container#data-wrapper:not([data-categories*="video"]) > .categories > #report > h2 {
  color: #FFF !important;
}
.resources main > article.page-container#data-wrapper:not([data-categories*="video"]):not([data-categories*="report"]) > .categories > #document > h2 {
  color: #FFF !important;
}
.resources main > article.page-container#data-wrapper:not([data-categories*="video"]):not([data-categories*="report"]):not([data-categories*="document"]) > .categories > #presentation > h2 {
  color: #FFF !important;
}
.resources main > article.page-container .card {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: left;
  transition: box-shadow 0.2s ease-out;
}
.resources main > article.page-container .card:hover {
  box-shadow: 0 0 29px rgba(0, 0, 0, 0.2);
}
.resources main > article.page-container .card-thumb > div {
  position: relative;
  padding-bottom: 55%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
}
.resources main > article.page-container .card-thumb > div .video > video {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
}
.resources main > article.page-container .card-thumb > div .video > * {
  display: none;
}
.resources main > article.page-container .card-thumb > div:before,
.resources main > article.page-container .card-thumb > div:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  transition: opacity 0.15s ease-out;
}
.resources main > article.page-container .card-thumb > div[style^="background-image:"]:after,
.resources main > article.page-container .card-thumb > div:before {
  opacity: 0;
}
.resources main > article.page-container .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  margin: 0;
  padding: 25px;
  border: 1px solid #dedede;
  border-top: none;
  background: #FFF;
}
.resources main > article.page-container .card-body h3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 1rem;
  font-size: 2.25rem;
  font-weight: 300;
}
.resources main > article.page-container .card-body h3 span.cat {
  display: block;
  margin-bottom: 1rem;
  padding: .5em;
  font-size: 1rem;
  font-weight: 500;
  color: #FFF;
  text-transform: uppercase;
  letter-spacing: .06em;
}
.resources main > article.page-container .card-body h3 div.name {
  display: block;
  width: 100%;
  margin: 0;
}
.resources main > article.page-container .card-body p {
  margin: 0;
  font-size: 16px;
}
.resources main > article.page-container .card-body .action {
  position: relative;
  align-self: flex-start;
  width: 100%;
  margin-top: 1.5rem;
}
.resources main > article.page-container .card-body .action > * {
  margin: 0;
  transition: all 0.15s ease-out;
}
.resources main > article.page-container .card-body .action > .video {
  display: none;
}
.resources main > article.page-container .card-body .action > div {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  opacity: 0;
  visibility: hidden;
}
.resources main > article.page-container .card-body .action > div > * {
  margin: 0 1rem 0 0;
}
@media (max-width: 400px) {
  .resources main > article.page-container .card-body .action > div > * {
    margin: 0 0.5rem 1rem 0;
    padding: 0.5em 1em;
  }
}
.resources main > article.page-container .card-body .action > div.variants > * {
  text-transform: uppercase;
}
.resources main > article.page-container .card-body .action > .variants,
.resources main > article.page-container .card-body .action.done > .button,
.resources main > article.page-container .card-body .action.done:hover > .button,
.resources main > article.page-container .card-body .action.language.done > .variants,
.resources main > article.page-container .card-body .action.language.done > .variants a,
.resources main > article.page-container .card-body .action.language.done:hover > .variants {
  opacity: 0;
  visibility: hidden;
}
.resources main > article.page-container .card-body .action.done > .downloaded {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 400px) {
  .resources main > article.page-container .card-body .action.language > a {
    position: absolute;
  }
  .resources main > article.page-container .card-body .action .downloaded {
    display: none;
  }
  .resources main > article.page-container .card-body .action .variants {
    position: static;
    display: block;
  }
}
.resources main > article.page-container .card.video > .card-thumb > div {
  background-color: #DC006B;
}
.resources main > article.page-container .card.video > .card-thumb > div:after {
  background-image: url('/assets/icon-video.svg');
  background-size: 120px;
}
.resources main > article.page-container .card.video > .card-body h3 {
  color: #DC006B;
}
.resources main > article.page-container .card.video > .card-body h3 span.cat {
  background-color: #DC006B;
}
.resources main > article.page-container .card.video > .card-body > .action .button {
  color: #323232;
  border-color: #DC006B;
}
.resources main > article.page-container .card.video > .card-body > .action .button:hover {
  color: #EBEBEB !important;
  background: #DC006B;
}
.resources main > article.page-container .card.video > .card-body > .action.done > .downloaded .button {
  color: #EBEBEB;
  background: #DC006B;
}
.resources main > article.page-container .card.video > .card-thumb > div:after {
  z-index: 3;
  opacity: 1;
}
.resources main > article.page-container .card.video:hover > .card-thumb > div:before {
  z-index: 2;
  opacity: 1;
  background-image: -moz-linear-gradient(top, rgba(220, 0, 107, 0) 0%, rgba(220, 0, 107, 0.6) 100%);
  background-image: -webkit-linear-gradient(top, rgba(220, 0, 107, 0) 0%, rgba(220, 0, 107, 0.6) 100%);
  background-image: linear-gradient(to bottom, rgba(220, 0, 107, 0) 0%, rgba(220, 0, 107, 0.6) 100%);
}
.resources main > article.page-container .card.video:hover > .card-body > .action > .button {
  color: #DC006B;
}
.resources main > article.page-container .card.report > .card-thumb > div {
  background-color: #455560;
}
.resources main > article.page-container .card.report > .card-thumb > div:after {
  background-image: url('/assets/icon-report.svg');
  background-size: 120px;
}
.resources main > article.page-container .card.report > .card-body h3 {
  color: #455560;
}
.resources main > article.page-container .card.report > .card-body h3 span.cat {
  background-color: #455560;
}
.resources main > article.page-container .card.report > .card-body > .action .button {
  color: #323232;
  border-color: #455560;
}
.resources main > article.page-container .card.report > .card-body > .action .button:hover {
  color: #EBEBEB !important;
  background: #455560;
}
.resources main > article.page-container .card.report > .card-body > .action.done > .downloaded .button {
  color: #EBEBEB;
  background: #455560;
}
.resources main > article.page-container .card.report > .card-thumb > div:after {
  z-index: 3;
  opacity: 1;
}
.resources main > article.page-container .card.report:hover > .card-thumb > div:before {
  z-index: 2;
  opacity: 1;
  background-image: -moz-linear-gradient(top, rgba(69, 85, 96, 0) 0%, rgba(69, 85, 96, 0.6) 100%);
  background-image: -webkit-linear-gradient(top, rgba(69, 85, 96, 0) 0%, rgba(69, 85, 96, 0.6) 100%);
  background-image: linear-gradient(to bottom, rgba(69, 85, 96, 0) 0%, rgba(69, 85, 96, 0.6) 100%);
}
.resources main > article.page-container .card.report:hover > .card-body > .action > .button {
  color: #455560;
}
.resources main > article.page-container .card.presentation > .card-thumb > div {
  background-color: #CBD302;
}
.resources main > article.page-container .card.presentation > .card-thumb > div:after {
  background-image: url('/assets/icon-presentation.svg');
  background-size: 120px;
}
.resources main > article.page-container .card.presentation > .card-body h3 {
  color: #CBD302;
}
.resources main > article.page-container .card.presentation > .card-body h3 span.cat {
  background-color: #CBD302;
}
.resources main > article.page-container .card.presentation > .card-body > .action .button {
  color: #323232;
  border-color: #CBD302;
}
.resources main > article.page-container .card.presentation > .card-body > .action .button:hover {
  color: #EBEBEB !important;
  background: #CBD302;
}
.resources main > article.page-container .card.presentation > .card-body > .action.done > .downloaded .button {
  color: #EBEBEB;
  background: #CBD302;
}
.resources main > article.page-container .card.presentation > .card-thumb > div:after {
  z-index: 3;
  opacity: 1;
}
.resources main > article.page-container .card.presentation:hover > .card-thumb > div:before {
  z-index: 2;
  opacity: 1;
  background-image: -moz-linear-gradient(top, rgba(203, 211, 2, 0) 0%, rgba(203, 211, 2, 0.6) 100%);
  background-image: -webkit-linear-gradient(top, rgba(203, 211, 2, 0) 0%, rgba(203, 211, 2, 0.6) 100%);
  background-image: linear-gradient(to bottom, rgba(203, 211, 2, 0) 0%, rgba(203, 211, 2, 0.6) 100%);
}
.resources main > article.page-container .card.presentation:hover > .card-body > .action > .button {
  color: #CBD302;
}
.resources main > article.page-container .card.document > .card-thumb > div {
  background-color: #009CDB;
}
.resources main > article.page-container .card.document > .card-thumb > div:after {
  background-image: url('/assets/icon-document.svg');
  background-size: 120px;
}
.resources main > article.page-container .card.document > .card-body h3 {
  color: #009CDB;
}
.resources main > article.page-container .card.document > .card-body h3 span.cat {
  background-color: #009CDB;
}
.resources main > article.page-container .card.document > .card-body > .action .button {
  color: #323232;
  border-color: #009CDB;
}
.resources main > article.page-container .card.document > .card-body > .action .button:hover {
  color: #EBEBEB !important;
  background: #009CDB;
}
.resources main > article.page-container .card.document > .card-body > .action.done > .downloaded .button {
  color: #EBEBEB;
  background: #009CDB;
}
.resources main > article.page-container .card.document > .card-thumb > div:after {
  z-index: 3;
  opacity: 1;
}
.resources main > article.page-container .card.document:hover > .card-thumb > div:before {
  z-index: 2;
  opacity: 1;
  background-image: -moz-linear-gradient(top, rgba(0, 156, 219, 0) 0%, rgba(0, 156, 219, 0.6) 100%);
  background-image: -webkit-linear-gradient(top, rgba(0, 156, 219, 0) 0%, rgba(0, 156, 219, 0.6) 100%);
  background-image: linear-gradient(to bottom, rgba(0, 156, 219, 0) 0%, rgba(0, 156, 219, 0.6) 100%);
}
.resources main > article.page-container .card.document:hover > .card-body > .action > .button {
  color: #009CDB;
}
.resources main > article.page-container .card.languages:hover .language:not(.done) .button {
  opacity: 0;
  visibility: hidden;
}
.resources main > article.page-container .card.languages:hover .language:not(.done) .variants,
.resources main > article.page-container .card.languages:hover .language:not(.done) .variants a {
  opacity: 1 !important;
  visibility: visible !important;
}
.header-video {
  position: absolute;
  z-index: -100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.header-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
}
@media (min-width: 768px) {
  .header-video + header {
    background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)) !important;
  }
}
@media only screen and (max-width: 767px) {
  .header-video {
    display: none;
  }
}
#modal article .video {
  display: block;
  height: 28vw;
  margin: -3em -2em;
}
#modal article .video > video,
#modal article .video > iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 1024px) {
  #modal article .video {
    height: auto;
  }
  #modal article .video > video,
  #modal article .video > iframe {
    min-height: 44vw;
  }
}
@media only screen and (max-width: 767px) {
  #modal article .video > video,
  #modal article .video > iframe {
    min-height: 50vw;
  }
}
.hub.executive-summaries h2.h1 {
  margin: 1em 0 0;
  letter-spacing: .06em;
}
.hub.executive-summaries h2.h1 + button {
  margin: 2em 0;
}
.hub.executive-summaries button.neutral {
  padding-top: .45em;
  padding-bottom: .45em;
  font-size: 100%;
  border-radius: 20px;
}
body.resources nav.main:not(.open) a,
body.data-analysis nav.main:not(.open) a,
body.executive-summaries nav.main:not(.open) a,
body.resources nav.main:not(.open) .active,
body.data-analysis nav.main:not(.open) .active,
body.executive-summaries nav.main:not(.open) .active,
body.resources nav.main:not(.open) a:hover,
body.data-analysis nav.main:not(.open) a:hover,
body.executive-summaries nav.main:not(.open) a:hover,
body.resources nav.main:not(.open) a:visited,
body.data-analysis nav.main:not(.open) a:visited,
body.executive-summaries nav.main:not(.open) a:visited {
  color: #323232;
}
body.resources nav.main:not(.open) .brand img.alt,
body.data-analysis nav.main:not(.open) .brand img.alt,
body.executive-summaries nav.main:not(.open) .brand img.alt {
  display: none;
}
body.resources nav.main:not(.open) .brand img,
body.data-analysis nav.main:not(.open) .brand img,
body.executive-summaries nav.main:not(.open) .brand img {
  display: block;
}
body.resources nav.main:not(.open).alt,
body.data-analysis nav.main:not(.open).alt,
body.executive-summaries nav.main:not(.open).alt {
  background-color: transparent;
}
body.resources nav.main:not(.open).alt img,
body.data-analysis nav.main:not(.open).alt img,
body.executive-summaries nav.main:not(.open).alt img {
  display: none;
}
body.resources nav.main:not(.open).alt img.alt,
body.data-analysis nav.main:not(.open).alt img.alt,
body.executive-summaries nav.main:not(.open).alt img.alt {
  display: block;
}
body.resources nav.main:not(.open).alt a,
body.data-analysis nav.main:not(.open).alt a,
body.executive-summaries nav.main:not(.open).alt a,
body.resources nav.main:not(.open).alt .active,
body.data-analysis nav.main:not(.open).alt .active,
body.executive-summaries nav.main:not(.open).alt .active,
body.resources nav.main:not(.open).alt a:hover,
body.data-analysis nav.main:not(.open).alt a:hover,
body.executive-summaries nav.main:not(.open).alt a:hover,
body.resources nav.main:not(.open).alt a:visited,
body.data-analysis nav.main:not(.open).alt a:visited,
body.executive-summaries nav.main:not(.open).alt a:visited {
  color: white;
}
body.resources nav.main:not(.open).alt .hamburger:not(.is-active) > span > span,
body.data-analysis nav.main:not(.open).alt .hamburger:not(.is-active) > span > span,
body.executive-summaries nav.main:not(.open).alt .hamburger:not(.is-active) > span > span {
  background-color: #EBEBEB;
}
body.resources nav.main:not(.open).alt .hamburger:not(.is-active) > span > span:before,
body.data-analysis nav.main:not(.open).alt .hamburger:not(.is-active) > span > span:before,
body.executive-summaries nav.main:not(.open).alt .hamburger:not(.is-active) > span > span:before,
body.resources nav.main:not(.open).alt .hamburger:not(.is-active) > span > span:after,
body.data-analysis nav.main:not(.open).alt .hamburger:not(.is-active) > span > span:after,
body.executive-summaries nav.main:not(.open).alt .hamburger:not(.is-active) > span > span:after {
  background-color: #EBEBEB;
}
body.resources nav.main .brand > img,
body.data-analysis nav.main .brand > img,
body.executive-summaries nav.main .brand > img {
  display: none;
}
body.resources nav.main .brand > img.alt,
body.data-analysis nav.main .brand > img.alt,
body.executive-summaries nav.main .brand > img.alt {
  display: block;
}
body.resources p,
body.data-analysis p,
body.executive-summaries p {
  font-family: 'Montreal', Helvetica, Arial, sans-serif;
}
_:-ms-fullscreen,
:root .resources main > article.page-container .card > .card-thumb > div::after {
  background-size: 220px;
}
/* IE11 */
